import React from "react"

import { Container } from "@components"
import SocialMedias from "./SocialMedias"
import { TypingEffect } from "@components/TypingEffect"
// import Meetups from "./Meetups"

export const Community = () => (
  <Container title={"Comunidade"} index={17} id="comunidade">
    <TypingEffect index={18}>
      <SocialMedias />
    </TypingEffect>
    {/* <Meetups /> */}
  </Container>
)
