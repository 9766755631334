import React from "react"
import { Button } from "@components"

import socialMedias from "@contents/social-medias"
import { List, Item } from "./SocialMedias.styles"

const SocialMedias = () => (
  <List>
    {socialMedias.map(socialMedia => (
      <Item key={socialMedia.id}>
        <Button
          link={socialMedia.link}
          icon={socialMedia.icon}
        >
          {socialMedia.id}
        </Button>
      </Item>
    ))}
  </List>
)

export default SocialMedias
