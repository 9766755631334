import React from "react"

import { Container, Button } from "@components"
import { AiOutlineFilePdf } from "react-icons/ai"
import { ButtonType } from "@components/Button"
import { TypingEffect } from "@components/TypingEffect"

export const Callforpapers = () => (
  <Container title={"Call for papers"} index={10} id="c4p">
    <TypingEffect index={11}>
      <Button
        type={ButtonType.primary}
        icon={<AiOutlineFilePdf />}
        link={`/files/manual-c4p.pdf`}
        >
        MANUAL DO PALESTRANTE
      </Button>
    </TypingEffect>
  </Container>
)
