import React from "react";

import { Container, Button, ButtonType } from "@components";
import Emerald from "./Emerald";
import Diamond from "./Diamond";
import Mediums from "./Mediums";
import Smalls from "./Smalls";
import ExtraSmalls from "./ExtraSmalls";
import { WrapperButtons } from "./Sponsors.style";
import { AiOutlineMail } from "react-icons/ai";
import { contact } from "@contents";
import { TypingEffect } from "@components/TypingEffect";
//
// import { AiOutlineMail } from "react-icons/ai";
//
// import { contact } from "@contents";
// import { WrapperButtons } from "./Sponsors.style";
// import { ButtonType } from "@components/Button";


export interface ISponsor {
  id: string;
  name: string;
  image: string;
  site?: string;
}

export type SponsorsTypesProps = {
  list?: ISponsor[]
}

export const Sponsors = () => (
  <Container title={"Patrocínio"} index={12} id="patrocinio">
    <Emerald />
    <Diamond />
    <Mediums />
    <Smalls />
    <ExtraSmalls />
    {/* <TypingEffect index={13}>
      <WrapperButtons>
        <Button
          type={ButtonType.primary}
          icon={<AiOutlineMail />}
          link={`mailto:${contact.email}`}
          style={{ margin: "auto" }}
          >
          QUERO SER PATROCINADOR
        </Button>
      </WrapperButtons>
    </TypingEffect> */}
  </Container>
);
