import React, { useState } from "react"
import ModalVideo from "react-modal-video"

import { conference } from "@contents"
import { PreviousYearsVideosList, IVideo } from "./PreviousYearsVideosList"
import {
  ConferenceContainer,
  Subtitle,
  Bio,
  Technologies,
} from "./Conference.styles"
import { Container, ReactBrAnimation } from "@components"
import { TypingEffect } from "@components/TypingEffect"

export const Conference = () => {
  const [openedVideo, setOpenedVideo] = useState<IVideo | null>(null)

  return (
    <Container id="evento" index={2} title={"A CONFERÊNCIA"}>
      <ConferenceContainer>
        <TypingEffect index={3}>
          <ReactBrAnimation dark={false} />
        </TypingEffect>
        <TypingEffect index={3}>
          <Subtitle>BLEEDING EDGE DEVELOPMENT</Subtitle>
        </TypingEffect>
        <TypingEffect index={4}>
          <Bio>{conference.bio}</Bio>
        </TypingEffect>
        <TypingEffect index={5}>
          <Technologies>{conference.technologies}</Technologies>
        </TypingEffect>
      </ConferenceContainer>
      <PreviousYearsVideosList setOpenedVideo={setOpenedVideo} />

      <ModalVideo
        isOpen={!!openedVideo}
        onClose={() => setOpenedVideo(null)}
        videoId={openedVideo?.videoId}
        channel="youtube"
        youtube={openedVideo?.listId ? {"list": openedVideo?.listId} : {}}
      />
    </Container>
  )
}

