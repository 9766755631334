import styled from "styled-components"

import { colors } from "@globals"

export const List = styled.ul`
  list-style-type: none;
  width: 100%;
  line-height: 1.8rem;
`

export const Item = styled.li`

  a {
    color: ${colors.grade.pink};
    text-decoration: none;
  }
`
