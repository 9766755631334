import styled from "styled-components";

import { colors } from "@globals";

export const StyledPreviousYearsVideosList = styled.ul`
  list-style-type: none;
  text-align: left;
  width: 100%;
  padding: 0;
`;

export const PreviousYearItem = styled.li`
  cursor: pointer;
  text-align: left;
`;

export const AsLink = styled.span`
  color: ${colors.grade.blue};
`