import styled from "styled-components";

import { colors } from "@globals";

export const ConferenceContainer = styled.div`
width: 100%;
`;

export const Subtitle = styled.h3`
  color: ${colors.grade.pink};
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 4px;
  margin: 0;
`;

export const Bio = styled.p`
  margin-top: 2rem;
`;

export const Technologies = styled.p`
  color: ${colors.grade.grey};
`;
