import React, { useState } from "react";

import {
  LineupList,
  LineupItem,
  Speaker,
  Talk,
  Picture,
  PictureWrapper,
  Line,
  LineWrapper,
  TalkInLine,
  NameInLine,
  CallTalk,
} from "./Lineup.styles";
import { lineup } from "@contents";
import { Button, Container } from "@components";
import { TypingEffect } from "@components/TypingEffect";

import Modal from 'react-modal';
import { borderColor } from "polished";
// import { CustomStyles } from "../../style/modalStyle";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    border: "2px solid green",
    boxShadow: "5px 5px 20px black",
    minWidth: "500px",
    maxWidth: "90vw"
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

export const Lineup = () => {
  if (!lineup.enabled) return null;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectTalk, setSelectTalk] = useState<{speaker: {
    name?: string;
    talk?: string;
    videoId?: string;
    picture?: null;
  }} | null>(null);

  const { data } = lineup;

  const lastItem = data.length - 1

  const closeModal = () => {
    setIsOpen(false)
    setSelectTalk(null)
  }

  const openModal = (talk: any) => {
    setIsOpen(true)
    setSelectTalk(talk)
  }

  return (
    // <Container id="lineup" index={2} title={"LINEUP"}>
    // <ConferenceContainer>
    //   <TypingEffect index={3}>
    //     <ReactBrAnimation dark={false} />
    //   </TypingEffect>
    //   <TypingEffect index={3}>
    //     <Subtitle>BLEEDING EDGE DEVELOPMENT</Subtitle>
    //   </TypingEffect>
    //   <TypingEffect index={4}>
    //     <Bio>{conference.bio}</Bio>
    //   </TypingEffect>
    //   <TypingEffect index={5}>
    //     <Technologies>{conference.technologies}</Technologies>
    //   </TypingEffect>
    // </ConferenceContainer>
    // <PreviousYearsVideosList setOpenedVideo={setOpenedVideo} />

    // <ModalVideo
    //   isOpen={!!openedVideo}
    //   onClose={() => setOpenedVideo(null)}
    //   videoId={openedVideo?.videoId}
    //   channel="youtube"
    //   youtube={openedVideo?.listId ? {"list": openedVideo?.listId} : {}}
    // />
    // </Container>
    <Container title={"Lineup"} index={3} id="lineup">
      <LineupList>
        {data.map((d, index) => {
          const hasSpeaker = d.speaker && d.speaker.name;

          return (
            <TypingEffect index={6 + index} key={index}>
              <LineupItem key={index}>
                {/* <LineWrapper>
                <PictureWrapper>
                  {talk.speaker ? (
                    // <Picture
                    //   src={talk.speaker.picture}
                    //   alt={talk.speaker.name}
                    //   title={talk.speaker.name}
                    // />
                    null
                  ) : (
                    <Hour>{talk.hour}</Hour>
                  )}
                </PictureWrapper>
              </LineWrapper> */}
                
                  {!hasSpeaker && <CallTalk style={{ cursor: "default" }}>{`> `}<TalkInLine>{d.hour}</TalkInLine> {` - ${d.description}`}</CallTalk>}
                  {hasSpeaker && <Talk onClick={() => openModal({...d})}><CallTalk>{`> `}<TalkInLine>{d.speaker?.talk}</TalkInLine>{d.speaker?.talk ? <> apresentado por <NameInLine>{d.speaker?.name}</NameInLine></> : null}</CallTalk></Talk>}
                
              </LineupItem>
            </TypingEffect>
          )
        })}
      </LineupList>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Talkers"
        shouldCloseOnOverlayClick={true}
      >
        <p>Talk: <strong style={{ color: "green" }}>{selectTalk?.speaker.talk}</strong></p>
        <p>Apresentado por: <strong style={{ color: "green" }}>{selectTalk?.speaker.name}</strong></p>
        {selectTalk?.speaker.picture ? <PictureWrapper><Picture
          src={selectTalk?.speaker.picture}
          alt={selectTalk?.speaker.name}
          title={selectTalk?.speaker.name}
        /></PictureWrapper> : null}
        {selectTalk?.speaker.videoId && <iframe height="355" style={{ margin: "auto", width: "100%" }} src={`https://www.youtube-nocookie.com/embed/${selectTalk?.speaker.videoId}?autoplay=1&controls=0&showinfo=0&rel=0`} title={selectTalk?.speaker.talk} frameBorder="0" allow="accelerometer; autoplay; nobranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>}
        <Button onClick={closeModal} style={{ marginTop: "1rem" }}>fechar</Button>
      </Modal>
    </Container>
  );
};
