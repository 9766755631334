import styled from "styled-components";

import { colors, shadowStyle } from "@globals";

export const SpeakersList = styled.ul`
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Speaker = styled.li`
  margin: 1em;
  width: 220px;
`;

export const PictureWrapper = styled.div`
  position: relative;
  /* border: 2px solid ${colors.main.secondary}; */
  border-radius: 15px;
  width: 100%;
  height: 220px;
  overflow: hidden;
  cursor: pointer;

  transition: transform 400ms ease;

  svg {
    font-size: 1.6em;
    position: absolute;
    /* z-index: 1; */
    bottom: 1px;
    right: 5px;
    color: ${colors.main.primary};
  }

  &:hover {
    transform: scale(1.02);
    transition: box-shadow 400ms ease;
    box-shadow: ${shadowStyle()};
  }
`;

export const Picture = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
`;

export const Name = styled.h4`
  margin: 0.5em 0;
  color: ${colors.main.primary};
  text-align: center;
  font-size: 1.5rem;
`;

export const Talk = styled.a`
  display: block;
  color: ${colors.dark};
  text-align: center;
  margin: 0 2em;
  font-size: 1rem;
  text-decoration: ${({ talkLink }) => (talkLink ? "underline" : "none")};
  cursor: ${({ talkLink }) => (talkLink ? "pointer" : "inherit")};
`;
