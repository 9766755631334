import React from "react";

import { Container, Button, Link } from "@components";
import { contact, supporters } from "@contents";
import { List, Item, SupportImage, IWantToBeASponsor } from "./Support.styles";
import { ButtonType } from "@components/Button";
import { AiOutlineMail } from "react-icons/ai";
import { TypingEffect } from "@components/TypingEffect";

export const Support = () => {
  if (!supporters.enabled) return null;

  const { data } = supporters;

  return (
    <Container index={14} title={"Apoio"} id="apoio">
      <TypingEffect index={15}>
        <List>
          {data.map((support) => (
            <Item key={support.id}>
              <Link href={support.link}>
                {/* <SupportImage
                  src={support.image}
                  alt={support.name}
                  title={support.name}
                /> */}
                <span> &#129309; {support.name}</span>
              </Link>
            </Item>
          ))}
        </List>
      </TypingEffect>

      {/* <TypingEffect index={16}>
        <IWantToBeASponsor>
          <Button
            link={`mailto:${contact.email}`}
            icon={<AiOutlineMail />}
          >
            QUERO SER UM APOIADOR
          </Button>
        </IWantToBeASponsor>
      </TypingEffect> */}
    </Container>
  );
};
