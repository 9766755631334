import React, { FC } from "react";
import { ISponsor, SponsorsTypesProps } from ".";

import { Link } from "@components";
import { emeraldSponsors } from "@contents";
import { SponsorList, SponsorItem, SponsorImage } from "./Sponsors.style";

const Emerald: FC<SponsorsTypesProps> = ({ list = emeraldSponsors }) => {
  if (!list) return null;

  return (
    <SponsorList>
      {list.map((emerald) => (
        <SponsorItem key={emerald.id}>
          <Link href={emerald.site}>
            <SponsorImage
              src={emerald.image}
              alt={emerald.name}
              title={emerald.name}
              type="emerald"
            />
          </Link>
        </SponsorItem>
      ))}
    </SponsorList>
  );
};

export default Emerald;
