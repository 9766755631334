import styled, { css } from "styled-components";

import { BiggerThanMedium } from "@style/breakpoints";

export const SponsorList = styled.ul`
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const SponsorItem = styled.li`
  margin: 2em;
`;

const sizeByType = (type) =>
  ({
    emerald: css`
      max-width: 280px;
      max-height: 180px;

      ${BiggerThanMedium} {
        max-width: 320px;
        max-height: 200px;
      }
    `,
    diamond: css`
      max-width: 200px;
      max-height: 120px;

      ${BiggerThanMedium} {
        max-width: 250px;
        max-height: 120px;
      }
    `,
    medium: css`
      max-width: 170px;

      ${BiggerThanMedium} {
        max-width: 220px;
      }
    `,
    small: css`
      max-width: 140px;

      ${BiggerThanMedium} {
        max-width: 170px;
      }
    `,
    extraSmall: css`
      max-width: 90px;

      ${BiggerThanMedium} {
        max-width: 120px;
      }
    `,
  }[type]);

export const SponsorImage = styled.img`
  ${({ type }) => sizeByType(type)}
  transition: transform 150ms ease;

  &:hover {
    transform: scale(0.96);
  }
`;
export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  a {
    margin: 0.3em;
  }
`;
