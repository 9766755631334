import styled from "styled-components";
import { colors } from "@globals";

export const List = styled.ul`
  /* display: flex;
  flex-wrap: wrap; */
  align-items: center;
  justify-content: left;
  padding: 0;
  list-style-type: none;
  width: 100%;
`;

export const Item = styled.li`
  /* margin: 2em; */

  span {
    text-decoration: none;
    color: ${colors.grade.pink};
  }
`;

export const SupportImage = styled.img`
  width: 170px;
`;

export const IWantToBeASponsor = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 2em;
  width: 100%;
`;
