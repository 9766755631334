import React from "react";

import {
  SEO,
  GlobalStyles,
  Menu,
} from "@components";

import {
  Callforpapers,
  Community,
  Conference,
  Contact,
  Home,
  Lineup,
  Speakers,
  Sponsors,
  Support,
} from "@containers";

import { sessions } from "@contents";

function IndexPage() {

  return (
    <main>
      <SEO title="2022" />
      <GlobalStyles />
        {sessions.home && <Home />}
        {sessions.conference && <Conference />}
        {sessions.speakers && <Speakers />}
        {sessions.lineup && <Lineup />}
        {sessions.callforpapers && <Callforpapers />}
        {sessions.sponsors && <Sponsors />}
        {sessions.support && <Support />}
        {sessions.community && <Community />}
        {sessions.contact && <Contact />}
        {sessions.menu && <Menu />}
    </main>
  );
}

export default IndexPage;
