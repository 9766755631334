import styled from "styled-components";

import { BiggerThanXLarge } from "@style/breakpoints";

export const ConfDate = styled.h3`
  font-size: 1.2rem;
`;

export const SubscribeButtonWrapper = styled.div`
  display: none;

  ${BiggerThanXLarge} {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const MobileSubscribeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4em;

  ${BiggerThanXLarge} {
    display: none;
  }
`;

export const LogoImg = styled.img`
  width: 70%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;
