import React, { FC, ReactElement } from "react";

import { TypingEffect } from "@components/TypingEffect";

import { conference } from "@contents";
import {
  PreviousYearItem,
  StyledPreviousYearsVideosList,
  AsLink
} from "./PreviousYearsVideosList.styles";
// import thumbConf2019 from "@assets/images/conf-2019.png";

export const PreviousYearsVideosList: FC<{ setOpenedVideo?: any }> = ({
  setOpenedVideo,
}): ReactElement => (
  <StyledPreviousYearsVideosList>
    {conference.videos.previousYears.map((video, index) => (
      <TypingEffect index={6+index}>
        <PreviousYearItem onClick={() => setOpenedVideo(video)} key={video.videoId}>
          <p>{video.year} - <AsLink>https://youtu.be/{video.videoId}</AsLink></p>
        </PreviousYearItem>
      </TypingEffect>
    ))}
  </StyledPreviousYearsVideosList>
);

export interface IVideo {
  year: string;
  videoId: string;
  listId?: string;
  thumb?: string;
}

