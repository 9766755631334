import React, { FC } from "react";
import { SponsorsTypesProps } from ".";

import { Link } from "@components";
import { mediumSponsors } from "@contents";
import { SponsorList, SponsorItem, SponsorImage } from "./Sponsors.style";

const Mediums: FC<SponsorsTypesProps> = ({ list = mediumSponsors }) => {
  if (!list) return null;

  return (
    <SponsorList>
      {list.map((medium) => (
        <SponsorItem key={medium.id}>
          <Link href={medium.site}>
            <SponsorImage
              src={medium.image}
              alt={medium.name}
              title={medium.name}
              type="medium"
            />
          </Link>
        </SponsorItem>
      ))}
    </SponsorList>
  );
};

export default Mediums;
