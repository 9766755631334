import React from "react";

import Logo from "@assets/images/reactpainel-pt.png";
import { LinkButton, Container } from "@components";
import { conference } from "@contents";

import { ConfDate, LogoImg } from "./Home.styles";
import { MdOutlineLiveTv } from "react-icons/md";
import { TypingEffect } from "@components/TypingEffect";

export const Home = () => (
  <Container title={"Home"} index={0} id="home">
    <TypingEffect index={0}>
      <div style={{ width: '100%', textAlign: "center" }}>
        <LogoImg src={Logo} alt="React Conf BR 2021" />
        <ConfDate>{conference.date}</ConfDate>
      </div>
    </TypingEffect>
    <LinkButton
      icon={<MdOutlineLiveTv />}
      link={`https://youtu.be/5TEWMO9nQQg`}
      style={{ margin: "2rem auto" }}
    >
      Assista a live
    </LinkButton>
  </Container>
);
